.bodyclass {
	font-family:Roboto;
	font-weight: 400;
	font-size: 13px;
	color: #3c3c3c;
	margin:0px;
	padding:0;
	line-height:19px;
	word-wrap:break-word;
	/*background:#f3f3f3 url('/assets/img/pattern_bg.png') left top repeat;*/
	width:100%;
	height:100%;
}

.linkclass { color:#a4c724; text-decoration:none; }
img {border:none;}
.linkclass .blacklink { color:#454545; }
.linkclass .blacklink:hover { color:#a4c724; }
.formclass { margin:0px; padding:0px; }
.clear { clear:both; }

.login-wrapper {
  height: 100%;
  display: flex;
  justify-content: center;
  background: #FFFFFF;
}

.login-container {
  width: 650px;
  padding: 70px 0;
  display: flex;
  justify-content: center;
}
.loging-left-section, .loging-right-section {-moz-box-sizing: border-box; -webkit-box-sizing: border-box; box-sizing: border-box; width: 50%; float: left; min-height: 300px;}
.loging-left-section {width: 49.8%; border-right: 1px solid #e9e9e9; text-align: center; padding: 25px 0px;}
.loging-right-section {
  padding: 10px 50px;
  width: 450px;
  max-width: 450px;
}
.loging-right-section .heading {
  float: left;
  margin-bottom: 10px;
  width: 100%;
  text-align: center;
}
.loging-right-section .heading img {
  margin: 10px;
}
.loging-right-section .heading h3 {
  font-size: 1.5rem;
  color: #3c3c3c;
  font-family: Roboto;
  margin: 0;
  font-weight: 400;
  line-height: 1.334;
  letter-spacing: 0;
}
.loging-right-section .text {float: left; margin-bottom: 20px;}
.loging-right-section .text p{ font-size: 14px; line-height: 16px; color: #3c3c3c; font-family: Roboto;}
.loging-right-section .form-section {float: left; }
.loging-right-section .form-section .section-block {float: left; margin-bottom: 10px;}
.loging-right-section .form-section .section-block label {display: block; font-size: 14px; font-weight: normal; font-family: Roboto; margin-bottom: 4px;}
.loging-right-section .form-section .section-block input[type="email"], .loging-right-section .form-section .section-block input[type="password"] {width: 200px; box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15) inset; border-radius: 3px 3px 3px 3px; border: 1px solid #999999; padding: 6px 4px;}
input[type="submit"].small-button { color: #ffffff; border: 1px solid #bb162e; cursor: pointer;  box-shadow: 1px 1px 2px #7a7a7a; margin-top: 5px; border-radius: 3px; background-color: #cc0f2a; padding: 4px 15px; font-size: 12px;}
/*.button-image {background: url('/assets/img/submit.png') no-repeat 0px 0px; border: 0; cursor: pointer; height: 52px; width: 112px;}*/
.loging-right-section .form-section .section-block input[type="text"]:focus, .loging-right-section .form-section .section-block input[type="password"]:focus {box-shadow:0px 0px 6px 0 rgba(150, 83, 147, 0.8) inset; outline: 0; border:1px solid rgba(202, 168, 168, 0.89);}
.loging-right-section .need-help {display: inline-block; font-size: 12px; color: #000000;}
.login-wrapper .nav-links {float: left; width: 650px; }
.login-wrapper .nav-links a {float: left; font-size: 12px; text-shadow: 0 1px 0 #FFFFFF;  color: #1D71BF; line-height: 25px;}
.login-wrapper .nav-links a:hover {color: #46A4FC;}
.login-wrapper .nav-links span {float: left; font-size: 12px; text-shadow: 0 1px 0 #FFFFFF;  color: #444444; margin: 0 5px; line-height: 25px;}
.login-wrapper .imgmargin {margin-top:80px;}
